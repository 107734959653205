<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row align-items-center">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('newsletterFilter')"
                  >
                    <CIcon
                      v-if="!loading"
                      size="lg"
                      name="cil-filter"
                      class="mr-2"
                    />
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton
                  color="success"
                  square
                  size="sm"
                  @click="openModalEvent('create','newsletterSteps', {langId: '', activeFlag: true, targetSubstypes: [ { label: 'NewsLetter', value: '1' }], onlyInterestedSubs: true}, 'Yeni Newsletter Ekle')"
                >
                  NewsLetter Ekle
                </CButton>
            </div>
          </div>
        </CCardHeader>

        <CCardBody>
          <CDataTable
            :items="newsletters"
            :fields="fields"
            :items-per-page="10"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            v-on:refresh="getAllNewsLettersDetail"
          >
            <template #activeFlag="{item}">
              <td>
                <CIcon
                  v-if="item.activeFlag > 0"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #onlyInterestedSubs="{item}">
              <td>
                <CIcon
                  v-if="item.onlyInterestedSubs > 0"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #targetSubstypes="{item}">
              <td>
                <CBadge color="info" v-for="i in item.targetSubstypes.split(',')" :key="i">
                  {{ comboTargetSubTypes[parseInt(i) - 1].label }}
                </CBadge>
              </td>
            </template>
            <template #numFail="{item}">
              <td>
                <CBadge color="danger" >
                  {{ item.numFail }} 
                </CBadge>
              </td>
            </template>
            
            <template #numSuccess="{item}">
              <td>
                <CBadge color="success" >
                  {{ item.numSuccess }}
                </CBadge>
              </td>
            </template>
            <template #newsLetterStatus="{item}">
              <td>
                <CBadge :color="comboNewsLetterStatus.find(e => e.value == item.newsLetterStatus).color" >
                  {{ item.newsLetterStatus }}
                </CBadge>
              </td>
            </template>
            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem
                    @click="openModalEvent('update', 'newsletterForm', item, 'Newsletter Düzenleme')">
                    Düzenle
                  </CDropdownItem>
                  <CDropdownItem 
                    @click="openModalEvent('photo', 'newsletterPhotos', item, 'Template Fotoğraf Yükleme ('+item.name+')')" >
                    Template Görselleri Yükle
                  </CDropdownItem>
                  <CDropdownItem  
                    @click="openModalEvent('status', 'newsletterStatus', item, 'Newsletter Durumunu Değiştir')">
                    Başlat/Durdur
                  </CDropdownItem>
                  <CDropdownItem 
                    @click="openModalEvent('preview', 'newsletterPreview', item, 'Newsletter Önizleme')">
                    Newsletter Önizle
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>
            <template #createTime="{item}">
              <td>{{ item.createTime && dateFormat(item.createTime) }} </td>
            </template>
            <template #sendFinishTime="{item}">
              <td>{{ item.sendFinishTime && dateFormat(item.sendFinishTime) }} </td>
            </template>
            <template #sendStartTime="{item}">
              <td>{{ item.sendStartTime && dateFormat(item.sendStartTime) }} </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crudNewsLetter"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="crudNewsLetter"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      :noFooter="noFooter"
      :loading="loading"
      :buttonTitle="buttonTitle"
      :noConfirm="noConfirm"
      ref="form"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterNewsLetters"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>

<script>
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import FilterSidebar from '../components/filterSidebar'
import { prepareFilterParams } from "@/helpers/custom"
import moment from 'moment'

export default {
  name: 'newsLetters',
  components: { FormModal, ConfirmModal, FilterSidebar },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'notification',
      form: 'newsletterForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      lastItem: {},
      openSidebar: false,
      filterForm: 'newsletterFilter',
      filterParams: {},
      noFooter: false,
      noConfirm: false,
      buttonTitle: 'Kaydet',

      comboTargetSubTypes: [
        { label: 'NewsLetter', value: '1' },
        { label: 'ProductSale', value: '2' },
        { label: 'ProductReprint', value: '3' },
        { label: 'Test', value: '4' },
        { label: 'Promotion', value: '5' }
      ],
      filters: [
        { type: 'numeric', dataIndex: 'id', field: 'id', comparison: 'eq'},
        { type: 'string', dataIndex: 'name', field: 'name' },
        { type: 'listString', dataIndex: 'targetSubstypes', field: 'targetSubstypes' },
        { type: 'listString', dataIndex: 'newsLetterStatus', field: 'newsLetterStatus'},
        { type: 'boolean', dataIndex: 'onlyInterestedSubs', field: 'onlyInterestedSubs'},
        { type: 'date', dataIndex: 'createTime', comparison: 'gt', field: 'createTime' },
        { type: 'date', dataIndex: 'endTime', comparison: 'lt', field: 'endTime' }
      ],
      comboDataList: [
        { label: 'Hepsi', value: '' },
        { label: 'Evet', value: 'true' },
        { label: 'Hayır', value: 'false' }
      ],
      comboNewsLetterStatus: [
        { label: 'ready', value: 'ready', color: 'success' },
        { label: 'sending', value: 'sending', color: 'warning' },
        { label: 'finished', value: 'finished', color: 'primary' },
        { label: 'stop', value: 'stop', color: 'danger' },
      ],
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        { key: 'id' },
        { key: 'activeFlag', label: 'Aktif' },
        { key: 'createTime', label: 'Oluşturma Tarihi' },
        { key: 'name', label: 'İsim' },
        { key: 'newsLetterStatus', label: 'Durumu' },
        { key: 'numFail', label: 'Hatalı Gönderim' },
        { key: 'numSuccess', label: 'Başarılı Gönderim' },
        { key: 'sendFinishTime', label: 'Bitiş Tarihi' },
        { key: 'sendStartTime', label: 'Başlama Tarihi' },
        { key: 'targetSubstypes', label: 'Hedef' },
        { key: 'onlyInterestedSubs', label: 'Only Interested Subs' }
      ],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.country) {
          this.activePage = Number(route.query.page)
        }
      }
    },
    modalProps(val){
      if(val == null){
        this.modalProps = {} 
        this.modalProps.targetSubstypes = [{ label: 'NewsLetter', value: '1' }]
        this.modalProps.onlyInterestedSubs = true
        this.modalProps.activeFlag = true
        this.modalProps.templatePath = '/velocity/templates/newsletter/'+(this.$store.getters.newsletters[0].id+ 1)+'.vm'
      }
    }
  },
  computed:{
    newsletters: function(){
      return this.$store.getters.newsletters;
    },
    loading: function(){
      return this.$store.getters.notificationLoading;
    }
  },
  methods: {
    openModalEvent(actionType, form, data, title, desc){
      this.buttonTitle = "Kaydet"
      this.noConfirm = false;
      if(form == 'newsletterPreview')
        this.noFooter = true;
      else if(form == 'newsletterPhotos'){
        this.noFooter = false;
        data.ids = []
        data.photos = [];
        data.links = [];
        data.templateLang = 'tr'
        data.activeTab = 1
        let formData = new FormData;
        formData.append('newsLetterId', data.id)
        formData.append('langId', 1)
        this.$store.dispatch('newsletterTemplate_getPhoto', formData)
      } else if(form == 'newsletterSteps'){
        this.buttonTitle = "İleri"
        this.noConfirm = true;
      }
      else
        this.noFooter = false;
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    openSidebarEvent( form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },
    rowClicked(item) {
      this.lastItem = item
    },
    dateFormat(date){
      return moment(date).format("DD.MM.YYYY HH:mm:ss")
    },
    async getAllNewsLettersDetail() {
      await this.$store.dispatch('newsletter_list')
    },

    async filterNewsLetters(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
      
      await this.$store.dispatch('newsletter_list', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
   
    async crudNewsLetter(item, actionType) {
      let tabIndex = 0;
      let tmpForm = ""

      if(this.form == 'newsletterSteps'){

        tabIndex = this.$refs.form.$refs.customComponent.tabIndex
        switch( tabIndex ){
          case 1:
            tmpForm = "newsletterForm";
            break;
          case 2: 
            tmpForm = "newsletterPhotos";
            break;
          case 3: 
            tmpForm = "newsletterPhotos";
            this.noFooter = true;
            break;
          case 4: 
            tmpForm = "newsletterPreview";
            break;
        }

      }

      if(this.form == 'newsletterForm'|| tmpForm == 'newsletterForm' ){
        let formData = new FormData()

        formData.append('newsLetterId', item.id ? item.id : '')
        formData.append('actionType', actionType)
        formData.append('activeFlag', item.activeFlag == undefined ? true : item.activeFlag)
        formData.append('name', item.name)
        formData.append('nameTr', item.nameTr)
        formData.append('nameEn', item.nameEn)
        formData.append('onlyInterestedSubs', item.onlyInterestedSubs == undefined ? true : item.onlyInterestedSubs)
        formData.append('targetSubstypes', item.targetSubstypes ? item.targetSubstypes.map(t => t.value).join(',') : '')
        formData.append('templatePath', item.templatePath ? item.templatePath : '')
        formData.append('bgColor', item.bgColor ? item.bgColor : '#000000')
        item.langId && item.langId != 0 && formData.append('langId', item.langId);

        await this.$store.dispatch('newsletter_action', formData)

      }else if(this.form == 'newsletterStatus' || tmpForm == 'newsletterStatus'){
        const params = {
          newsLetterId: item.id,
          statusType: item.newsLetterStatus
        }
        await this.$store.dispatch('newsletter_status', params)
       
      }else if(this.form == 'newsletterPhotos' || tmpForm == 'newsletterPhotos'){
        let formData = new FormData()
        let valid = true;
        let validInfo = "";


        if(item.photos && item.photos.length > 0){

            item.photos.map ( (p,i) => {
              if(p && p !== ""){
                  formData.append('templateImageFiles', p.size > 0 ? p : new Blob([]))
              } else {
                valid = false
                validInfo += i + ". resim "; 
              }
            })

            item.links.map ( l => {
              formData.append('imageLinks', l)
            })

            if(valid){

              formData.append('newsLetterId', item.id )
              formData.append('languageType', item.templateLang)
              formData.append('ids', item.ids)


              await this.$store.dispatch('newsletterTemplate_photo', formData)

            } else {
              const toast = {
                  msg: 'Lütfen bütün resimleri yükleyiniz.' + validInfo + " yüklenmemiş.",
                  header: 'Hata',
                  color: 'danger'
              }
              this.$store.commit('showToast', toast)
            }

        }

      }else if(this.form == 'newsletterFile'){
        let formData = new FormData()

        formData.append('newsLetterId', item.id )
        formData.append('templateFile', item.file)

        await this.$store.dispatch('newsletterTemplate_file', formData)

      }
      if(this.form == 'newsletterSteps'){

       if(this.$store.getters.notificationStatus.success){

        if(tmpForm == 'newsletterForm'){

          await this.getAllNewsLettersDetail()

          if(this.$store.getters.newsletters[0])
            this.$refs.form.$refs.customComponent.newsletterId = this.$store.getters.newsletters[0].id;
          else
            this.$refs.form.$refs.customComponent.newsletterId = null
        } else if( tmpForm == 'newsletterStatus'){
          this.openModalEvent()
          this.getAllNewsLettersDetail()
        }
        
        this.$refs.form.$refs.customComponent.tabIndex = tabIndex + 1;

       } else if(tmpForm == 'newsletterPhotos' && (!item.photos || item.photos.length == 0) ){
          this.$refs.form.$refs.customComponent.tabIndex = tabIndex + 1;
       }

      } else {
        if(this.$store.getters.notificationStatus.success){
          this.openModalEvent()
          this.getAllNewsLettersDetail()
        }
      }
      
    }
  },

  mounted() {
    this.getAllNewsLettersDetail()
    this.$store.dispatch('lang_list')

  }
}
</script>
